<template>
  <div class="home" v-loading="loading" style="overflow: hidden">
    <div :style="{width: divWidth, height: divHeight}">
      <el-carousel id="carousel" :height="bannerheight" interval="5000" arrow="always">
        <el-carousel-item>
          <!--          <h3 class="medium">{{ item }}</h3>ferifbn3-->
          <!--          <div style="background-image: url('../images/20180412/banner1.jpg');width: 100%;height: 100%;z-index: 30"></div>-->
          <a href="#" @click.prevent="this.$router.push('/product')">
            <img src="../../assets/img/banner/banner2.jpg" style="height: 100%;width: 100%;object-fit: fill" />
          </a>

        </el-carousel-item>
        <el-carousel-item>
          <!--          <h3 class="medium">{{ item }}</h3>ferifbn3-->
          <!--          <div style="background-image: url('../images/20180412/banner1.jpg');width: 100%;height: 100%;z-index: 30"></div>-->
          <img src="../../assets/img/banner/banner.jpg" style="height: 100%;width: 100%;object-fit: fill" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div style="width: 100%;text-align: center;">
      <div class="content-product">
        <!--          <div class="product-big-img">-->
        <!--            <el-dialog :title="dialogTitle" :visible.sync="dialogTableVisible">-->
        <!--              <img v-lazy="dialogUrl" alt />-->
        <!--            </el-dialog>-->
        <!--          </div>-->
        <div>
          <p id="content-product-title">产品中心</p>
          <p style="color: #B1B1B2">PRODUCT</p>
          <!--            <div class="border"></div>-->
        </div>
        <div class="product-show">
          <div class="product" v-for="product in productlist" :key="product.strId" @click="detailsClick('product',product.title,product.created_at,product.content)">
              <div class="imgdiv"><img :src="product.coverImage" style="object-fit: scale-down;"></div>
              <div class="spandiv"><span>{{ product.title }}</span></div>
          </div>
        </div>
        <a href="#" @click.prevent="this.$router.push('/product')">查看更多</a>
      </div>
    </div>
    <div style="width: 100%;text-align: center;background-color: rgb(230, 237, 239)">
      <div class="anlishow">
        <div>
          <p class="anlishowtitle">案例展示</p>
          <p style="color: #B1B1B2">CASE</p>
        </div>
        <div class="swiperdiv" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
          <!--          <swiper style="height: 395px" ref="mySwipers">-->
          <!--            <swiper-slide-->
          <!--                style="display: flex;flex-direction: column;justify-content: center;align-content: center;height: 370px !important;" >-->
          <swiper
              style="height: 395px" ref="mySwipers"
              class="swiper-container"
              :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',}"
              :pagination="{ clickable: true }"
              :autoplay="{ autoplay: true ,delay:3500 ,pauseOnMouseEnter: true,}"
              loop>
            <swiper-slide class="swiper-slide" v-for="cases in caselist" :key="cases"
                          style="display: flex;flex-direction: column;justify-content: center;align-content: center;height: 370px !important;">
              <div style="margin: 0 auto">
                <div class="divIncludeImg" v-for="casei in cases" :key="casei.strID" @click="detailsClick('case',casei.title,casei.created_at,casei.content)">

                    <img :src="casei.coverImage" style="object-fit:cover ">
                    <div class="blackdiv">
                      <div>
                        <p class="blackdivtitle">{{ casei.title }}</p>
                        <p class="blackdivcontent">{{ casei.simpleDesc }}</p>
                      </div>
                    </div>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination"></div>
            <!-- 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。-->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
            <!-- 如果需要滚动条 -->
            <!-- <div class="swiper-scrollbar"></div> -->
          </swiper>
          <!--            </swiper-slide>-->
          <!--          </swiper>-->
          <!--          <swiper-->
          <!--              class="swiper-container"-->
          <!--              :navigation="{-->
          <!--              nextEl: '.swiper-button-next',-->
          <!--              prevEl: '.swiper-button-prev',}"-->
          <!--              :pagination="{ clickable: true }"-->
          <!--              :autoplay="{ autoplay: true }"-->
          <!--              loop>-->
          <!--          <swiper-slide class="swiper-slide" v-for="(item, i) in imgs" :key="i">-->
          <!--              <img :src="item" alt=""/>-->
          <!--            </swiper-slide>-->
          <!--            &lt;!&ndash; 如果需要分页器 &ndash;&gt;-->
          <!--            <div class="swiper-pagination"></div>-->
          <!--            &lt;!&ndash; 如果需要导航按钮 左右箭头。如果放置在swiper-container外面，需要自定义样式。&ndash;&gt;-->
          <!--            <div class="swiper-button-prev"></div>-->
          <!--            <div class="swiper-button-next"></div>-->
          <!--            &lt;!&ndash; 如果需要滚动条 &ndash;&gt;-->
          <!--            &lt;!&ndash; <div class="swiper-scrollbar"></div> &ndash;&gt;-->
          <!--          </swiper>-->
        </div>
        <a href="#" @click.prevent="this.$router.push('/case')">查看更多</a>
      </div>
      <!--            <img src="../images/20180412/anliShow.jpg"  style="width: 60%;">-->
    </div>
    <div style="width: 100%;text-align: center;">
      <div class="ourshow">
        <div>
          <a href="#news"></a>
          <p class="ourshowtitle">新闻资讯</p>
          <p style="color: #B1B1B2">NEWS</p>
        </div>
        <div id="news">
          <div id="photos" @mouseenter="mouseEnterNews" @mouseleave="mouseLeaveNews">
            <!--            <swiper id="swiperid" v-bind:options="swiperOption" ref="mySwipersNews">-->
            <!--
                        </swiper>-->
            <swiper
                id="swiperid"
                :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',}"
                :pagination="{ clickable: true,bulletClass : 'my-bullet',bulletActiveClass: 'my-bullet-active',}"
                :autoplay="{ autoplay: true,delay:3500,pauseOnMouseEnter: true,}"
                loop>
              <swiper-slide id="swiper-slideid" v-for="news in newslist" :key="news.strID" >
                  <div @click="detailsClick('news',news.title,news.created_at,news.content)">
                    <img :src="news.coverImage">
                    <div class="bylines">
                      <p>{{ news.title }}</p>
                      <p>{{ news.subTitle }}</p>
                    </div>
                  </div>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
              <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
            </swiper>
          </div>
          <ul id="contents">
            <li v-for="news in newslist" :key="news">
              <div class="datediv">
                <span class="day">{{ new Date(news.created_at).getDate() }}</span>
                <span class="yearandmonth">{{
                    new Date(news.created_at).getFullYear()
                  }}-{{ new Date(news.created_at).getMonth() + 1 }} </span>
              </div>
              <div class="newsdiv">
                <a href="#"
                   @click.prevent="detailsClick('news',news.title,news.created_at,news.content)">{{ news.title }}</a>
                <p>{{ news.simpleDesc }}</p>
              </div>
            </li>

          </ul>
        </div>
        <a href="#" @click.prevent="this.$router.push('/news')">查看更多</a>
      </div>
      <!--      <img src="../images/20180412/anliShow.jpg"  style="width: 60%;">-->
    </div>

  </div>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";
// Import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";
// Import Swiper core and required modules
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
export default {
  name: "HelloWorld",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      loading: false,
      bannerheight: 0,
      divHeight: 0,
      divWidth: 0,

      caselist: [],
      newslist: [],
      productlist: [],
      newsQuery: {
        "pageNum": 1,
        "pageCount": 5,
        "type": 'news'
      },
      caseQuery: {
        "pageNum": 1,
        "pageCount": 12,
        "type": 'case'
      },
      productQuery: {
        "pageNum": 1,
        "pageCount": 4,
        "type": 'product'
      },
      imgs: [
        require("../../assets/img/img_1.png"),
        require("../../assets/img/img_2.png"),
        require("../../assets/img/img_3.png"),
      ],
    };
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };

  },
  created() {

    this.newsquery()
    this.casequery()
    this.productquery()
    this.$nextTick(() => {
      this.toCurrent()
    })
  },
  mounted(){
    this.divWidth = document.body.clientWidth + "px";
    this.divHeight = document.body.clientHeight - 70 + "px";

    this.bannerheight = document.body.clientHeight - 70 + "px";
    window.onresize = () => {
      return (() => {
        console.log(this.divWidth)
        this.divWidth = document.body.clientWidth + "px";
        this.divHeight = document.body.clientHeight - 70 + "px";

        this.bannerheight = document.body.clientHeight - 70 + "px";
      })();
    };
  },
  computed: {},
  // mounted() {
  //   var that = this;
  //   // <!--把window.onresize事件挂在到mounted函数上-->
  //   window.onresize = () => {
  //     return (() => {
  //       window.fullHeight = document.documentElement.clientHeight;
  //       window.fullWidth = document.documentElement.clientWidth;
  //       that.bannerheight = window.fullHeight;  // 高
  //       that.bannerWidth = window.fullWidth; // 宽
  //     })()
  //   };
  // },
  methods: {
    detailsClick(type,title,date,content){
      localStorage.setItem("type",type)
      localStorage.setItem("title",title)
      localStorage.setItem("date",date)
      localStorage.setItem("content",content)
      this.$router.push("/detail")
    },
    toCurrent() {
      let anchorId = sessionStorage.getItem('anchorId')
      console.log(anchorId)
      if (anchorId) {
        if (anchorId === 'productcenter') {
          document.documentElement.scrollTop = window.innerHeight
        } else if (anchorId === 'caseshow') {
          document.documentElement.scrollTop = window.innerHeight + 787
        } else if (anchorId === 'news') {
          document.documentElement.scrollTop = window.innerHeight + 787 + 755
        }
      }
      sessionStorage.removeItem('anchorId')
    },
    casequery() {
      this.$axios({
        url: this.localpath + 'officialweb/searchArticles',
        method: 'post',
        data: JSON.stringify(this.caseQuery),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
            }
      }).then(res => {
        if (res.data.code === 0) {
          var len = res.data.msg.articles.length

          for (var i = 0; i < len; i += 3) {
            var cases = []
            for (var j = i; j < i + 3 && j < len; j++) {
              cases.push(res.data.msg.articles[j])
            }
            this.caselist.push(cases)
          }
          console.log(this.caselist)
        }
      })
    },
    newsquery() {
      this.$axios({
        url: this.localpath + 'officialweb/searchArticles',
        method: 'post',
        data: JSON.stringify(this.newsQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.newslist = res.data.msg.articles
        }
      })
    },
    productquery() {
      this.$axios({
        url: this.localpath + 'officialweb/searchArticles',
        method: 'post',
        data: JSON.stringify(this.productQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
            }
      }).then(res => {
        if (res.data.code === 0) {
          this.productlist = res.data.msg.articles
        }
      })
    },
  },

};
</script>

<style lang="scss">
.my-bullet {
  width: 9px;
  height: 9px;
  display: inline-block;
  border-radius: 50%;
  background: #ffffff;

  &:nth-child(2) {
    margin: 0 0.5rem;
  }
}

.my-bullet-active {
  background: #4889f3;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}


//产品中心
.content-product {
  padding-top: 70px;
  padding-bottom: 60px;
  width: 1200px;
  margin: 0 auto;

  #content-product-title {
    font-size: 2em;
    line-height: 2em;
    letter-spacing: 2px;
    font-weight: bold;
  }

  .product-show {
    width: 1200px;

    margin: auto;
    margin-top: 50px;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;

    .product {
      height: 410px;
      width: 280px;
      display: inline-block;
      //border: 1px solid #f0f0f0;
      border: 1px solid rgb(210, 210, 210);
      overflow: hidden;
      cursor:pointer;
      .imgdiv{
        height: 360px;
        line-height: 360px;
        img {
          //border: 1px solid rgb(200, 200, 200);
          height: 340px;
          width: 279px;
          vertical-align: middle;
          margin: 0 auto;
          transition: transform 0.4s;
        }

        img:hover {
          transform: scale(1.07);
        }
      }

      .spandiv {

        //border-top: 1px solid rgb(200, 200, 200);
        height: 50px;

        span {
          line-height: 50px;
        }

        span:hover {
          color: #017bc3;
          text-decoration: underline;
        }
      }

    }


  }
  >a {
    border: 1px solid black;
    color: black;
    width: 110px;
    height: 40px;
    display: block;
    margin: 40px auto;
    line-height: 38px;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;

    transition: background-color 0.3s, color 0.3s;
    -webkit-transition: background-color 0.3s, color 0.3s; /* Safari */
  }

  >a:hover {
    background-color: #2265bc;
    color: white;
  }
}

.swiper-slide {
  font-size: 24px;
  // text-align: center;
  // line-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 500px;


}

//经典案例
.case-item {
  width: 1100px;
  height: 500px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  li {
    width: 330px;
    height: 250px;
    list-style: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-origin: content-box;
    margin: 5px;
    position: relative;
    overflow: hidden;

    &:hover {
      .case-item-hover {
        opacity: 1;
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

//经典案例hover
.case-item-hover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  overflow: hidden;
  background-color: rgba(225, 56, 52, 0.7);

  .hover-title {
    height: 50px;
    color: #fff;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    margin-top: 20px;
  }

  .bottom {
    border-bottom: 1px solid #fff;
    width: 60px;
    margin: 0 auto;
  }

  .more {
    width: 90px;
    padding: 5px 5px;
    margin: 0 auto;
    margin-top: 100px;
    border: 2px solid #fff;

    span {
      color: #fff;
      font-size: 20px;
    }
  }
}

// .swiper-slide:nth-child(2n) {
//   background: skyblue;
// }
// .swiper-slide:nth-child(2n-1) {
//   background: seashell;
// }
.slide-one {
  background: url('../../assets/img/banner1.jpg') no-repeat center;
  background-size: cover;
}

.slide-two {
  background: url('../../assets/img/banner1.jpg') no-repeat center;
  background-size: cover;
}

.slide-three {
  background: url('../../assets/img/banner1.jpg') no-repeat center;
  background-size: cover;
}

//最新资讯
.news-content {
  width: 1240px;
  margin: 0 auto;
  margin-top: 40px;
  display: flex;
  justify-content: center;

  &-item {
    width: 400px;
    display: flex;
    flex-direction: column;

    .item-img {
      width: 360px;
      height: 230px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-origin: content-box;
      margin: 0 auto;
    }

    .el-divider {
      background-color: #fff;
      height: 3px;

      .el-divider__text {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        padding: 0px;
        color: #fff;
      }
    }

    .item-content {
      width: 360px;
      height: 230px;
      margin: 0 auto;
      //border: 1px solid paleturquoise;
      h3 {
        font-size: 22px;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        font-size: 15px;
        height: 80px;
        overflow: hidden;
        margin: 10px 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        white-space: normal !important;
        word-wrap: break-word;
      }

      span {
        display: block;
        font-size: 14px;
        text-align: end;
      }

      h3,
      p,
      span {
        color: #fff;
      }
    }
  }
}

.order {
  order: -1;
}

.order-img {
  order: 1;
}

.anlishow {
  padding: 70px 0 70px;
  width: 1200px;
  margin: 0 auto;

  a {
    border: 1px solid black;
    color: black;
    width: 110px;
    height: 40px;
    display: block;
    margin: 35px auto;
    line-height: 38px;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;

    transition: background-color 0.3s, color 0.3s;
    -webkit-transition: background-color 0.3s, color 0.3s; /* Safari */
  }

  a:hover {
    background-color: #2265bc;
    color: white;
  }
}

.anlishowtitle {
  font-size: 2em;
  line-height: 2em;
  letter-spacing: 2px;
  font-weight: bold;
}

.swiperdiv {
  width: 1200px;
  height: 380px;
  margin-top: 40px;

  .divIncludeImg {
    width: 360px;
    height: 360px;
    float: left;
    padding: 0 5px;
    cursor:pointer;
    img {
      width: 360px;
      height: 360px;

    }

    .blackdiv {
      position: absolute;
      top: 5px;
      z-index: 10;
      background-color: rgb(0, 0, 0, 0);
      width: 360px;
      height: 360px;
      color: rgb(255, 255, 255, 0);

      transition: background-color 1s, color 1s;
      //-webkit-transition: background-color 1s, color 1s; /* Safari */
      div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .blackdivtitle {
          padding-bottom: 7px;
          font-family: Tahoma;
          font-size: 18px;
          font-weight: 400;
          font-style: normal;
          text-decoration: none;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.5;
        }

        .blackdivcontent {
          margin: 0 auto;
          width: 260px;
          font-family: Tahoma;
          font-size: 14px;
          font-weight: 400;
          font-style: normal;
          text-decoration: none;
          text-align: center;
          line-height: 20px;
        }
      }
    }

    .blackdiv:hover {
      background-color: rgb(0, 0, 0, 0.7);
      color: rgb(255, 255, 255, 1);
    }
  }
}

.ourshow {
  width: 1200px;
  margin: 0 auto;
  padding: 70px 0;

  .ourshowtitle {
    font-size: 2em;
    line-height: 2em;
    letter-spacing: 2px;
    font-weight: bold;
  }

  #news {
    width: 1200px;
    height: 405px;
    margin: 50px 0;

    //.swiper-button-next {
    //  background-image: url("../../assets/icon/toRight.png") !important;
    //  background-color: rgb(0, 0, 0, 0.2);
    //  border-radius: 5px;
    //}
    //
    //.swiper-button-prev {
    //  background-image: url("../../assets/icon/toLeft.png") !important;
    //  background-color: rgb(0, 0, 0, 0.2);
    //  border-radius: 5px;
    //}
    //
    //.swiper-button-prev, .swiper-button-next {
    //
    //  width: 44px !important;
    //
    //  background-size: 44px 44px !important;
    //
    //}

    #swiperid {
      width: 600px;
      height: 405px;
      float: left;

      #swiper-slideid {
        height: 405px !important;
        cursor:pointer;
        > div {
          height: 405px !important;

          img {
            width: 600px;
            height: 405px;
          }

          > .bylines {
            position: absolute;
            width: 100%;
            height: 66px;
            bottom: 30px;
            text-align: center;
            color: #fff;

            p:nth-child(1) {
              margin: 12px 0 8px;
              font-size: 16px;
            }

            p:nth-child(2) {
              margin: 8px 0;
              font-size: 12px;
            }
          }
        }
      }

    }

    #contents {
      width: 570px;
      height: 400px;
      float: right;

      //li:first-child {
      //  margin-top: 0;
      //  list-style: none;
      //}

      li {
        margin: 20px 0;
        position: relative;
        list-style: none;
        height: 56px;

        .datediv {
          float: left;
          width: 90px;
          color: #014099;

          .day {
            color: #014099;
            font-size: 37px;
            display: block;
          }

          .yearandmonth {
            display: block;
          }
        }

        .newsdiv {
          float: right;

          a {
            font-size: 18px;
            width: 460px;
            display: block;
            text-align: left;

            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            text-decoration: none;
            -webkit-transition: .3s;
            color: black;
            -o-transition: .3s;
            -moz-transition: .3s;
            transition: .3s;
          }

          a:hover {
            color: blue;
          }

          p {
            width: 460px;
            text-align: left;

            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;

            overflow: hidden;
            font-size: 14px;
            line-height: 16px !important;
            padding-top: 5px;
            color: #999;
          }
        }


      }
    }
  }

  > a {
    border: 1px solid black;
    color: black;
    width: 110px;
    height: 40px;
    display: block;
    margin: 35px auto;
    line-height: 38px;
    font-size: 16px;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;

    transition: background-color 0.3s, color 0.3s;
    -webkit-transition: background-color 0.3s, color 0.3s; /* Safari */
  }

  > a:hover {
    background-color: #2265bc;
    color: white;
  }
}

@keyframes blackdivkey {
  from {
    opacity: 0
  }
  to {
    opacity: 60%
  }
}

@-webkit-keyframes blackdivkey /* Safari and Chrome */
{
  from {
    opacity: 0
  }
  to {
    opacity: 60%
  }
}

@keyframes imgkey {
  from {
    width: 360px;
    height: 360px;
  }
  to {
    width: 400px;
    height: 400px;
  }
}

@-webkit-keyframes imgkey /* Safari and Chrome */
{
  from {
    width: 360px;
    height: 360px;
  }
  to {
    width: 400px;
    height: 400px;
  }
}

</style>
